<template>
  <div class="boxTra" ref="boxTra">
    <h1 class="h1_title">{{data_.introduce.author}}</h1>
    <!-- 头部EDP区域 -->
    <div class="Edp" @click="articlePush(data_.introduce)">
      <div class="topImg"><img :src="baseUrl+data_.introduce.thumb" alt=""></div>
      <div class="bottom">
        <div class="title">{{data_.introduce.title}}</div>
        <div class="nav">{{data_.introduce.description}}</div>
      </div>
      <!-- 右侧蒙版背景 -->
      <div class="background"></div>
    </div>
    <!-- 标杆项目 -->
    <div class="bg">
      <h1 class="h1_title">标杆项目 <a href="javascript:;" @click="articleList('bgxm')">MORE></a></h1>
      <nav v-for="(item,i) in data_.benchmark.item" :key="item.id" @click="articlePush(item)">
        <div class="left_img">
          <img :src="baseUrl + item.thumb" alt="" v-if="item.thumb">
        </div>
        <section>
          <img :src="bgxmImg2[i]" alt="">
          <div class="section_text">
            <div class="title">{{item.title}}</div>
            <div class="content_">{{item.description}}</div>
          </div>
        </section>
      </nav>
    </div>
    <!-- 新闻中心 -->
    <div class="xw margin_top">
      <h1 class="h1_title">新闻中心 <a href="javascript:;" @click="articleList('Xwzx')">MORE></a></h1>
      <nav v-for="item in data_.news.item" :key="item.id" @click="articlePush(item)">
        <div class="xwLeft_img">
          <img :src="baseUrl + item.thumb" alt="">
        </div>
        <section>
          <div class="left">
            <div class="title">{{item.title}}</div>
            <div class="nav">{{item.description}}</div>
          </div>
          <div class="right">
            <span>{{item.created_at.slice(8,10)}}</span>
            <a href="javascript:;">{{item.created_at.slice(0,7).replace("-",'.')}}</a>
          </div>
        </section>
      </nav>
    </div>
    <!-- 项目动态 -->
    <div class="xm margin_top">
      <h1 class="h1_title">项目动态 <a href="javascript:;" @click="articleList('Xmdt')">MORE></a></h1>
      <div class="nav">
        <nav v-for="item in data_.project.item" :key="item.id" @click="articlePush(item)">
          <div class="top"><div class="imgbox"><img :src="baseUrl + item.thumb" alt=""></div></div>
          <div class="bottom">
            <nav>{{item.title}}</nav>
            <nav>{{item.description}}</nav>
          </div>
        </nav>
      </div>
    </div>
    <!-- 合作企业 -->
    <div class="hz margin_top">
      <h1 class="h1_title">合作企业 <a href="javascript:;">MORE></a></h1>
      <div class="top">
        <img src="https://xq-images.obs.cn-north-4.myhuaweicloud.com/20220906105944yRf23c" alt="">
        <!-- <nav v-for="item in data_.cooperation.item" :key="item.id"><img :src="baseUrl + item.thumb" alt="" @click="articlePush(item)"></nav> -->
      </div>
    </div>
    
  </div>
  <!-- 右侧电话和报名 -->
    <signUp />
</template>

<script setup>
import { get_Navaeticle_xq,getHttp } from '@/api/API.js'
import {ref,onMounted, reactive, nextTick} from 'vue'
import { useRouter,useRoute, } from 'vue-router'
import base64_ from '@/hooks/useBase64text.js'
import signUp from '@/components/phonesignUp.vue' 
const router = useRouter()
const route = useRoute()
let routeData = JSON.parse(base64_.decode(route.params.obj))

let down = ref(null)
const boxTra = ref(null)
onMounted(() => {
  // 300毫秒后，页面做动画的显示
setTimeout(() => {
  boxTra.value.style.opacity = 1
  boxTra.value.style.transform = "translateY(0)"
},300)

})
const baseUrl = process.env.VUE_APP_BASE_URL
let data_ = reactive({
  introduce:{thumb:''},
  types:[],
  benchmark:{},
  news:{},
  project:{},
  cooperation:{}
})
// 获取社会培训数据
let tranningList = async() => {
  const {data:res} = await getHttp("/api/overview",{})
  for(let key in res.data){
    for(let key2 in data_){
      if(key == key2){
         data_[key] = res.data[key]
      }
    }
  }
}
tranningList()
// 标杆项目右边的图片
let bgxmImg2 = reactive([
  'https://s1.ax1x.com/2022/08/11/v8jIuq.png',
  'https://s1.ax1x.com/2022/08/11/v8vE2d.png'
])

// 点击头部tab栏
let jump = (item,index) => {
  if(item.id == 151) return //如果点击第一个就啥也不做
  glTab("xxx",index)
  router.push({
    name:item.vue_url,
    params:{obj:base64_.encode(JSON.stringify(
      {
           title1:routeData.title1,
           title2:item.title,
           type_id:item.id,
           index:0,
           isBanner_nav_id:item.id,
           id:44
      }
    ))}
  })
}
// 点击跳转详情
let articlePush = (item) => {
  router.push({
    name:"Article_xq_time",
    params:{obj:base64_.encode(JSON.stringify({
      article_wz_id:item.id,
      title1:routeData.title1,
      id:routeData.id,
      type_id:item.id,
      index:0,
      isBanner_nav_id:item.id,
    }))}
  })
}
// 跳转列表；
let articleList = (str) => {
 switch(str){
  case 'bgxm':
    let bgxmdata = data_.benchmark
    router.push({
      name:"Bgxm",
      params:{obj:base64_.encode(JSON.stringify({
        title1:routeData.title1,
        title2:bgxmdata.title,
        id:routeData.id,
        type_id:bgxmdata.id,
        index:0,
        isBanner_nav_id:bgxmdata.id,
      }))}
    })
  break
  case 'Xwzx':
    let xwzxdata = data_.news
     router.push({
      name:"Wzlist",
      params:{obj:base64_.encode(JSON.stringify({
        title1:routeData.title1,
        title2:xwzxdata.title,
        id:routeData.id,
        type_id:xwzxdata.id,
        index:0,
        isBanner_nav_id:xwzxdata.id,
      }))}
    })
    break
   case 'Xmdt':
    let xmdtdata = data_.project
     router.push({    
      name:"Wzlist",
      params:{obj:base64_.encode(JSON.stringify({
        title1:routeData.title1,
        title2:xmdtdata.title,
        id:routeData.id,
        type_id:xmdtdata.id,
        index:0,
        isBanner_nav_id:2,
      }))}
    })
    break
 }
}
</script>

<style lang="less" scoped>
@import url("../../../style/training.less");
</style>  